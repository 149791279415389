import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

const LocationFilter = ({ locations, selectedLocation, onLocationChange }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLocationSelect = (location) => {
        onLocationChange(location);
        handleClose();
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClick}
                fullWidth
                sx={{
                    height: '56px',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    '& .MuiButton-label': {
                        fontWeight: 600
                    }
                }}
            >
                {selectedLocation || 'Filter by Location'}
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleLocationSelect(null)}>All Locations</MenuItem>
                {locations.map((location) => (
                    <MenuItem
                        key={location}
                        onClick={() => handleLocationSelect(location)}
                    >
                        {location}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default LocationFilter;