const baseUrl = 'https://mmrc.fleetster.net';
const imgSource = 'https://troov-mmrc.imgix.net/';
const placeholder = 'https://static.wixstatic.com/media/ad8a69_e868df086d874bd58a177c43e37343d7~mv2.png';

export const fetchVehicles = async (start, end) => {
    let url = `${baseUrl}/vehicles/63811673837e77c01a71635e/public`;
    if (start && end) {
        url += `?startDate=${start.toISOString()}&endDate=${end.toISOString()}`;
    }
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Remove vehicles with no plate (invalid)
    const data = (await response.json()).filter(item => item.numberPlate);

    data.forEach(vehicle => {
        // Replace image URLs with imgix cache
        vehicle.image = vehicle.image.includes('-image.jpg')
            ? vehicle.image = imgSource + vehicle.image.match(/\/(\w+-image.jpg)/)[1]
            : vehicle.image = placeholder;
    });

    return data;
};