import React, { useState, useEffect } from 'react';
import { Grid2, Container, Typography, Card, CardContent, CardMedia, CircularProgress, TextField, Box } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { addHours, addMinutes } from 'date-fns';
import QRCode from 'react-qr-code';
import LocationFilter from './LocationFilter';
import { fetchVehicles } from './api';

const REFRESH_TIMEOUT = 5 * 60 * 1000; // 5 minutes in milliseconds

function App() {
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: addHours(new Date(), 24)
    });
    const [minStartDate, setMinStartDate] = useState(new Date());
    const [minEndDate, setMinEndDate] = useState(addMinutes(minStartDate, 30));
    const [displayedDateRange, setDisplayedDateRange] = useState({ ...dateRange });
    const [fetchDateRange, setFetchDateRange] = useState({ ...dateRange });
    const [lastInteraction, setLastInteraction] = useState(new Date());
    const [isKioskMode, setIsKioskMode] = useState(false);
    const appDownloadUrl = 'https://list.midmichrentcars.com/app-download';

    // Add scaling logic for kiosk mode
    useEffect(() => {
        if (isKioskMode) {
            // Function to calculate and apply scale
            const setKioskScale = () => {
                const width = window.innerWidth;
                const height = window.innerHeight;
                const targetWidth = 1920; // 1080p width
                const targetHeight = 1080; // 1080p height

                // Calculate scale factors for both dimensions
                const scaleX = width / targetWidth;
                const scaleY = height / targetHeight;

                // Use the smaller scale to ensure content fits
                const scale = Math.min(scaleX, scaleY);

                // Apply the scaling transform to the main container
                const rootElement = document.getElementById('kiosk-container');
                if (rootElement) {
                    rootElement.style.transform = `scale(${scale})`;
                    rootElement.style.transformOrigin = 'top center';
                    rootElement.style.width = `${targetWidth}px`;
                    rootElement.style.height = `${targetHeight}px`;
                    rootElement.style.margin = '0 auto';
                }
            };

            // Set initial scale
            setKioskScale();

            // Update scale on window resize
            window.addEventListener('resize', setKioskScale);
            return () => window.removeEventListener('resize', setKioskScale);
        }
    }, [isKioskMode]);

    useEffect(() => {
        // Check for path-based location and kiosk matching
        const path = window.location.pathname.split('/');
        let lastPathItem = path.pop();
        if (lastPathItem) {
            if (lastPathItem === 'kiosk') {
                setIsKioskMode(true);
                lastPathItem = path.pop();
            };
            if (lastPathItem) {
                const pathLower = lastPathItem.toLowerCase();
                const matchedLocation = locations.find(loc =>
                    loc.toLowerCase().includes(pathLower)
                );
                if (matchedLocation) {
                    setSelectedLocation(matchedLocation);
                    return;
                }
            }
        }

        // Fallback to query parameter handling
        const params = new URLSearchParams(window.location.search);
        const locationParam = params.get('location');
        const kioskParam = params.get('kiosk');
        if (kioskParam === 'yes') { setIsKioskMode(true) };

        if (locationParam) {
            const decodedLocation = decodeURIComponent(locationParam);
            setSelectedLocation(decodedLocation);
        }
    }, [locations]);

    useEffect(() => {
        const getVehicles = async () => {
            setLoading(true);
            try {
                const data = await fetchVehicles(fetchDateRange.startDate, fetchDateRange.endDate);
                setVehicles(data);

                // Extract unique locations
                const uniqueLocations = [...new Set(data.map(vehicle => vehicle.locationName))];
                setLocations(uniqueLocations);
            } catch (e) {
                setError('Failed to fetch vehicles');
                console.error('Error:', e);
            } finally {
                setLoading(false);
            }
        };

        getVehicles();
    }, [fetchDateRange]);

    useEffect(() => {
        const resetTimer = () => {
            setLastInteraction(new Date());
        };

        // Add event listeners for user interaction
        window.addEventListener('click', resetTimer);
        window.addEventListener('touchstart', resetTimer);

        const intervalId = setInterval(() => {
            const timeSinceLastInteraction = new Date() - lastInteraction;
            // Only refresh if timeout have passed since last interaction
            if (timeSinceLastInteraction >= REFRESH_TIMEOUT) {
                const now = new Date();
                setMinStartDate(now);
                setMinEndDate(addMinutes(now, 30));
                setDateRange(prev => {
                    if (prev.startDate < now) {
                        return {
                            startDate: now,
                            endDate: addHours(now, 24)
                        };
                    }
                    return prev;
                });
                window.location.reload();
                setLastInteraction(new Date()); // Reset the interaction timer after refresh
            }
        }, REFRESH_TIMEOUT);

        // Clean up event listeners
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('touchstart', resetTimer);
        };
    }, [lastInteraction]);

    const handleStartDateChange = (newStartDate) => {
        setLastInteraction(new Date());
        setDisplayedDateRange(prev => ({
            startDate: newStartDate,
            endDate: addHours(newStartDate, 24)
        }));
    };

    const handleEndDateChange = (newEndDate) => {
        setLastInteraction(new Date());
        setDisplayedDateRange(prev => ({
            ...prev,
            endDate: newEndDate
        }));
    };

    const handleDateRangeAccept = () => {
        setFetchDateRange(displayedDateRange);
    };

    const handleLocationChange = (location) => {
        setLastInteraction(new Date());
        setSelectedLocation(location);

        // Update URL with new location parameter
        const url = new URL(window.location);
        if (location) {
            url.searchParams.set('location', encodeURIComponent(location));
        } else {
            url.searchParams.delete('location');
        }
        window.history.pushState({}, '', url);
    };

    const filteredVehicles = (selectedLocation
        ? vehicles.filter(vehicle => vehicle.locationName === selectedLocation)
        : vehicles
    ).sort((a, b) => (b.available ? 1 : 0) - (a.available ? 1 : 0));

    if (loading) return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh'
        }}>
            <CircularProgress />
        </Box>
    );

    if (error) return <Typography color="error">{error}</Typography>;

    const renderContent = () => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="lg">
                <Card sx={{
                    mb: 2,
                    background: 'linear-gradient(to right, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                    borderRadius: 2
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 1
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on mobile
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: { xs: 2, sm: 3 }, // Add some spacing between elements
                        }}>
                            <img
                                src="/mmrc-logo.png"
                                alt="MMRC Logo"
                                style={{
                                    height: isKioskMode ? '120px' : '60px',
                                    transition: 'transform 0.3s ease',
                                    ':hover': {
                                        transform: 'scale(1.05)'
                                    }
                                }}
                            />
                            <Typography
                                variant="h3"
                                component="h1"
                                sx={{
                                    fontWeight: 600,
                                    color: '#01B4FF',
                                    position: 'relative',
                                    fontSize: isKioskMode ? '4rem' : { xs: '1.75rem', sm: '3rem' },
                                    textAlign: { xs: 'center', sm: 'left' }
                                }}
                            >
                                24/7 Current Availability
                            </Typography>
                        </Box>
                        {isKioskMode && selectedLocation && (
                            <Typography
                                variant="h4"
                                sx={{
                                    mt: 2,
                                    color: '#666',
                                    fontWeight: 600,
                                    textAlign: 'center'
                                }}
                            >
                                {selectedLocation}
                            </Typography>
                        )}
                    </Box>
                </Card>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mb: 4,
                    flexWrap: 'wrap',
                    gap: 3,
                    width: '100%',
                    maxWidth: '800px',
                    mx: 'auto'
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {isKioskMode ? (
                            <>
                                <img src="/app-store-badge.svg" alt="Download on the App Store" style={{ height: '40px', marginBottom: '10px' }} />
                                <img src="/google-play-badge.png" alt="Get it on Google Play" style={{ height: '40px' }} />
                            </>
                        ) : (
                            <>
                                <a href="https://apps.apple.com/us/app/mmrc/id6446317034" target="_blank" rel="noopener noreferrer">
                                    <img src="/app-store-badge.svg" alt="Download on the App Store" style={{ height: '40px', marginBottom: '10px' }} />
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=mmrc.fleetster.de&hl=en" target="_blank" rel="noopener noreferrer">
                                    <img src="/google-play-badge.png" alt="Get it on Google Play" style={{ height: '40px' }} />
                                </a>
                            </>
                        )}
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}> {/* Hide QR code on mobile */}
                        <QRCode value={appDownloadUrl} size={120} />
                    </Box>
                    <Box sx={{ maxWidth: '300px' }}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                color: '#d32f2f',
                                textAlign: 'center',
                                fontSize: { xs: '1.25rem', sm: '1.5rem' } // Smaller on mobile
                            }}
                        >
                            {isKioskMode ? 'SCAN QR CODE' : 'DOWNLOAD NOW'}
                            <br />
                            to book with our
                            <br />
                            Mobile App
                        </Typography>
                    </Box>
                </Box>                
                <Typography
                    variant="h6"
                    sx={{
                        mb: 2,
                        textAlign: 'center',
                        fontWeight: 600,
                        color: '#666',
                    }}
                >
                    {isKioskMode ? 'TAP TO ' : ''}FILTER FOR AVAILABILITY AND COST
                </Typography>
                <Grid2 container spacing={2} sx={{
                    mb: 2,
                    justifyContent: 'center',
                    '& .MuiGrid2-root': {
                        minHeight: isKioskMode ? '120px' : '70px'
                    }
                }}>
                    {!isKioskMode && (
                        <Grid2 item xs={12} md={3}>
                            <LocationFilter
                                locations={locations}
                                selectedLocation={selectedLocation}
                                onLocationChange={handleLocationChange}
                            />
                        </Grid2>
                    )}
                    <Grid2 item xs={12} md={3}>
                        <DateTimePicker
                            label="Start Date & Time"
                            value={displayedDateRange.startDate}
                            onChange={handleStartDateChange}
                            onAccept={handleDateRangeAccept}
                            minDateTime={minStartDate}
                            sx={{
                                '& .MuiInputBase-root': {
                                    fontSize: isKioskMode ? '1.8rem' : '1.1rem',
                                    fontWeight: 500,
                                    height: isKioskMode ? '100px' : 'auto',
                                    padding: isKioskMode ? '8px 14px' : undefined,
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: isKioskMode ? '1.8rem' : '1.1rem',
                                    fontWeight: 600,
                                    transform: isKioskMode ? 'translate(14px, 32px)' : undefined,
                                    '&.MuiInputLabel-shrink': {
                                        transform: isKioskMode ? 'translate(14px, -12px) scale(0.75)' : undefined
                                    }
                                },
                                '& .MuiCalendarPicker-root': {
                                    transform: isKioskMode ? 'scale(1.5)' : undefined,
                                    transformOrigin: 'top center'
                                }
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid2>
                    <Grid2 item xs={12} md={3}>
                        <DateTimePicker
                            label="End Date & Time"
                            value={displayedDateRange.endDate}
                            onChange={handleEndDateChange}
                            onAccept={handleDateRangeAccept}
                            minDateTime={minEndDate}
                            sx={{
                                '& .MuiInputBase-root': {
                                    fontSize: isKioskMode ? '1.8rem' : '1.1rem',
                                    fontWeight: 500,
                                    height: isKioskMode ? '100px' : 'auto',
                                    padding: isKioskMode ? '8px 14px' : undefined,
                                },
                                '& .MuiInputLabel-root': {
                                    fontSize: isKioskMode ? '1.8rem' : '1.1rem',
                                    fontWeight: 600,
                                    transform: isKioskMode ? 'translate(14px, 32px)' : undefined,
                                    '&.MuiInputLabel-shrink': {
                                        transform: isKioskMode ? 'translate(14px, -12px) scale(0.75)' : undefined
                                    }
                                },
                                '& .MuiCalendarPicker-root': {
                                    transform: isKioskMode ? 'scale(1.5)' : undefined,
                                    transformOrigin: 'top center'
                                }
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                    {filteredVehicles.map(vehicle => (
                        <Grid2 item xs={12} sm={6} md={4} lg={3} key={vehicle.vehicleId} sx={{ display: 'flex' }}>
                            <Card sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: 300, // Set a maximum width for larger screens
                                opacity: vehicle.available ? 1 : 0.5,
                                position: 'relative',
                            }}>
                                <CardMedia
                                    component="img"
                                    sx={{
                                        height: 200,
                                        objectFit: 'contain',
                                        backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    }}
                                    image={vehicle.image}
                                    alt={`${vehicle.brand} ${vehicle.model}`}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    {!vehicle.available && (
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%) rotate(-15deg)',
                                                backgroundColor: 'rgba(255, 0, 0, 0.7)',
                                                color: 'white',
                                                padding: '5px 10px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            BOOKED
                                        </Typography>
                                    )}
                                    <Typography variant="h6" component="div" noWrap>
                                        {vehicle.brand} {vehicle.model}
                                    </Typography>
                                    {!isKioskMode && (
                                        <Typography variant="body2" color="text.secondary" noWrap>
                                            Location: {vehicle.locationName}
                                        </Typography>
                                    )}
                                    <Typography variant="body2" color="text.secondary">
                                        Plate: {vehicle.numberPlate}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Trip Cost: ${vehicle.cost}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        (plus taxes & fees)
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))}
                </Grid2>
            </Container>
        </LocalizationProvider>
    );

    // Return different wrappers based on kiosk mode
    return isKioskMode ? (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            overflow: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            bgcolor: '#f5f5f5',
        }}>
            <Box id="kiosk-container" sx={{
                width: '1920px',
                minHeight: '1080px', // Changed from height to minHeight
                position: 'relative',
                pb: 4, // Add padding at the bottom
                '&::-webkit-scrollbar': {  // Optional: style the scrollbar
                    width: '12px'
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                    borderRadius: '6px'
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#666'
                }
            }}>
                {renderContent()}
            </Box>
        </Box>
    ) : renderContent();
}

export default App;